import Cookie from 'js-cookie';
import { retrieveAuthTokenName } from '@/utils/index';

export const getAccessToken = () => {
   return Cookie.get(retrieveAuthTokenName('access'));
};

export class AuthToken {
   constructor(token = getAccessToken()) {
      this.token = token;
      this.decoded = {
         exp: 0,
      };

      if (token) this.decoded = '';
   }

   get isAuthenticated() {
      return this.token ? true : false;
   }

   get bearerString() {
      return `Bearer ${this.token}`;
   }
}
