import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
//import { cacheAdapterEnhancer } from 'axios-extensions';
import {
   ApiRoutesWithoutPrefix as ApiRoutes,
   LocalStorageKeys,
   Pages,
} from '@/config/constant';
import environment from '@/config/environment';
import Cookie from 'js-cookie';
import { AuthToken, getAccessToken } from './authToken';
import { retrieveAuthTokenName } from '@/utils/index';

const REFRESH_URL = '/user/refresh';
function logoutUser() {
   Cookie.remove(retrieveAuthTokenName('refresh'));
   Cookie.remove(retrieveAuthTokenName('access'));
   window.localStorage.removeItem('auth_token');
   localStorage.removeItem(LocalStorageKeys.LOGIN_USER);
   localStorage.removeItem(LocalStorageKeys.LOGIN_URL);
   localStorage.removeItem(LocalStorageKeys.IS_BUY_FLOW);
   window.location.replace(Pages.LOGIN);
}

export const axiosInstance = axios.create({
   baseURL: environment.apiUrl,
   headers: {
      Accept: 'application/json',
   },
});

export const cachedAxiosInstance = axios.create({
   baseURL: environment.apiUrl,
   headers: {
      'Cache-Control': 'no-cache',
   },
   //adapter: cacheAdapterEnhancer(axios.defaults.adapter),
});

export const noAuthAxiosInstance = axios.create({
   baseURL: environment.apiUrl,
});

axiosInstance.interceptors.request.use((config) => {
   const token = getAccessToken();

   if (token) config.headers['Authorization'] = 'Bearer ' + token;

   const locale = localStorage.getItem(LocalStorageKeys.LOCALE);
   if (token) config.headers['X-LOCALE'] = locale;

   return config;
});

cachedAxiosInstance.interceptors.request.use((config) => {
   const token = getAccessToken();

   if (token) config.headers['Authorization'] = 'Bearer ' + token;

   const locale = localStorage.getItem(LocalStorageKeys.LOCALE);
   if (token) config.headers['X-LOCALE'] = locale;

   return config;
});

axiosInstance.interceptors.response.use(
   (request) => request,
   (error) => {
      if (error.message === 'Network Error' && !error.status && !error.response) {
         return;
      }

      return Promise.reject(error);
   },
);

const refreshAuthLogic = async (failedRequest) => {
   const refreshToken = Cookie.get(retrieveAuthTokenName('refresh')) || '';
   const defaultRedirect = Pages.MY_PROFILE;
   const lastKnownLocation =
      window.location.pathname === Pages.LOGIN
         ? defaultRedirect
         : window.location.pathname;

   if (refreshToken) {
      return axios
         .post(
            REFRESH_URL,
            {
               refresh_token: refreshToken,
            },
            {
               baseURL: environment.apiUrl,
            },
         )
         .then((resp) => {
            const data = resp?.data?.[0]?.data;
            const newToken = data?.Token?.access_token;
            const refreshToken = data?.Token?.refresh_token;

            const decoded = new AuthToken(newToken);

            failedRequest.response.config.headers['Authorization'] = decoded.bearerString;
            Cookie.set(retrieveAuthTokenName('access'), newToken, { secure: true });
            Cookie.set(retrieveAuthTokenName('refresh'), refreshToken, {
               secure: true,
               sameSite: 'Strict',
            });
         })
         .catch((err) => {
            // save current page
            localStorage.setItem(LocalStorageKeys.LAST_KNOWN_LOCATION, lastKnownLocation);
            // only logout if token refresh fails
            if (
               err?.response?.status === 401 ||
               //err?.response?.status === 400 ||
               err?.response?.data?.code === 'token_not_valid' ||
               err?.response?.data?.[0]?.message === 'Client authentication failed' ||
               err?.response?.data?.code === 'Unauthenticated.'
            ) {
               logoutUser();
            }
         });
   } else {
      // no logout when it is the login that fails
      if (
         failedRequest?.response?.config?.url !== ApiRoutes.LOGIN &&
         window.location.pathname !== Pages.LOGIN &&
         window.location.pathname !== Pages.SIGN_UP
      ) {
         // save current page
         localStorage.setItem(LocalStorageKeys.LAST_KNOWN_LOCATION, lastKnownLocation);
         logoutUser();
      }
   }
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
   pauseInstanceWhileRefreshing: true,
});
createAuthRefreshInterceptor(cachedAxiosInstance, refreshAuthLogic, {
   pauseInstanceWhileRefreshing: true,
});

export function fetcher(url, config) {
   if (url !== undefined) {
      return axiosInstance.get(url, config).then((res) => {
         const data = res?.data;
         //if the request header has the x-restricted-fields parameter, add it in the response
         if (res?.headers['x-restricted-fields'] && data && typeof data === 'object') {
            data['_data_restricted'] = res?.headers['x-restricted-fields'];
         }

         return data;
      });
   } else {
      // eslint-disable-next-line no-console
      console.warn('Cannot fetch undefined URL');
   }
}

// use this to fetch cachable data outside swr
export function fetcherWithCache(url, cache) {
   if (url === undefined) {
      // eslint-disable-next-line no-console
      console.warn('Cannot fetch undefined URL');
      return;
   }

   return cachedAxiosInstance.get(url, cache).then((res) => {
      const data = res?.data;
      //if the request header has the x-restricted-fields parameter, add it in the response
      if (res?.headers['x-restricted-fields'] && data && typeof data === 'object') {
         data['_data_restricted'] = res?.headers['x-restricted-fields'];
      }
      return data;
   });
}

export const multiFetcher = (...urls) => {
   return Promise.all(urls.map((url) => fetcher(url)));
};
