import Vue from 'vue';
import Router from 'vue-router';
import { Pages, UserRolesName } from '@/config/constant';
import { getLoginUser } from '@/utils';

const today = new Date().getDate();

if (
   today > window.localStorage.getItem('loginUserDate') ||
   window.localStorage.getItem('loginUserDate') == null
) {
   window.localStorage.clear();
}

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer');
const HomeContainer = () => import('@/containers/HomeContainer');
// Views
const Dashboard = () => import('@/views/toutPaie/Dashboard');

// Views - Pages
const Login = () => import('@/views/pages/Login');
const changepassword = () => import('@/views/pages/change-password');
const changepasswordsuccessfully = () =>
   import('@/views/pages/changepassword-successfully');
const Managers = () => import('@/views/toutPaie/Managers');
const Stores = () => import('@/views/toutPaie/Stores');
const ProductGroups = () => import('@/views/toutPaie/Product-Groups');
const Products = () => import('@/views/toutPaie/Products');
const Ads = () => import('@/views/toutPaie/Ads');
const Posts = () => import('@/views/toutPaie/Posts');
const Orders = () => import('@/views/toutPaie/Orders');
const Users = () => import('@/views/toutPaie/Users');
const Payment = () => import('@/views/toutPaie/Payment');
const PaymentAccept = () => import('@/views/toutPaie/PaymentAccept');
const PaymentDeny = () => import('@/views/toutPaie/PaymentDeny');
const PaymentCancel = () => import('@/views/toutPaie/PaymentCancel');
const StoreImage = () => import('@/views/toutPaie/Store-Image');
const ForgotPassword = () => import('@/views/toutPaie/Forgot-Password');
const ResetPassword = () => import('@/views/toutPaie/Reset-Password');
const AllProductView = () => import('@/views/toutPaie/all-productView');
const SignUp = () => import('@/views/pages/Sign-up');
const ApprrovalList = () => import('@/views/toutPaie/ApprovalList');
const Directory = () => import('@/views/toutPaie/Directory');
const DirectoryHome = () => import('@/views/toutPaie/DirectoryHome');
const BuyerSubProfile = () => import('@/views/toutPaie/BuyerSubProfile');
const Commission = () => import('@/views/toutPaie/Commission');
const Currencies = () => import('@/views/toutPaie/Currencies');
const Profile = () => import('@/views/toutPaie/profile');
const Home = () => import('@/views/pages/home');
const HomeProductDetails = () => import('@/views/toutPaie/home-productDetails');
const payment = () => import('@/views/toutPaie/payment-product');
const ProductAddress = () => import('@/views/toutPaie/Address-product');
const HomeProductDescription = () => import('@/views/toutPaie/product-description');
const Offer = () => import('@/views/toutPaie/Offer');
const Chat = () => import('@/views/toutPaie/chat');
const ProductApprrovalList = () => import('@/views/toutPaie/productApprovalList');
const OfferApprovalList = () => import('@/views/toutPaie/offerApprovalList');
const Forums = () => import('@/views/toutPaie/forums');
const ForumsMessage = () => import('@/views/toutPaie/forums-message');
const ForumsMessageDetail = () => import('@/views/toutPaie/forums-message-detail');
const Weather = () => import('@/views/toutPaie/weather');
const Message = () => import('@/views/toutPaie/Message');
const PaymentType = () => import('@/views/toutPaie/payment-type');
Vue.use(Router);

const router = new Router({
   mode: 'history',
   linkActiveClass: 'open active',
   scrollBehavior: () => ({ y: 0 }),
   routes: [
      {
         path: '/',
         redirect: Pages.MANAGERS,
         name: 'Home',
         component: DefaultContainer,
         children: [
            {
               path: Pages.DASHBOARD,
               name: 'Dashboard',
               component: Dashboard,
            },
            {
               path: Pages.STORES,
               name: 'Stores',
               component: Stores,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.MANAGER,
                     UserRolesName.TRADER,
                     UserRolesName.SELLER,
                     UserRolesName.USER,
                     UserRolesName.TECHNICIAN,
                     UserRolesName.RESELLER,
                     UserRolesName.ADMINISTRATOR,
                  ],
               },
            },
            {
               path: Pages.MANAGERS,
               name: 'Managers',
               component: Managers,
               meta: { visibility: [UserRolesName.SUPER_ADMIN] },
            },
            {
               path: Pages.PRODUCT_GROUPS,
               name: 'Product Groups',
               component: ProductGroups,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.MANAGER,
                     UserRolesName.TRADER,
                     UserRolesName.SELLER,
                     UserRolesName.RESELLER,
                  ],
               },
            },
            {
               path: Pages.PRODUCTS,
               name: 'Products',
               component: Products,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.MANAGER,
                     UserRolesName.TRADER,
                     UserRolesName.SELLER,
                     UserRolesName.USER,
                     UserRolesName.TECHNICIAN,
                     UserRolesName.RESELLER,
                     UserRolesName.ADMINISTRATOR,
                  ],
               },
            },
            {
               path: Pages.OFFER,
               name: 'Offer',
               component: Offer,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.MANAGER,
                     UserRolesName.TRADER,
                     UserRolesName.SELLER,
                     UserRolesName.USER,
                     UserRolesName.TECHNICIAN,
                     UserRolesName.RESELLER,
                     UserRolesName.ADMINISTRATOR,
                  ],
               },
            },
            {
               name: 'Pending Products Approval',
               path: Pages.PENDING_PRODUCTS_APPROVAL,
               component: ProductApprrovalList,
            },
            {
               name: 'Pending Offer Approval',
               path: Pages.PENDING_OFFERS_APPROVAL,
               component: OfferApprovalList,
            },
            {
               path: Pages.ADS,
               name: 'Advertising Products',
               component: Ads,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.MANAGER,
                     UserRolesName.USER,
                     UserRolesName.TECHNICIAN,
                     UserRolesName.RESELLER,
                  ],
               },
            },
            {
               path: Pages.POSTS,
               name: 'Posts',
               component: Posts,
               meta: {
                  visibility: [UserRolesName.SUPER_ADMIN, UserRolesName.TECHNICIAN],
               },
            },
            {
               path: Pages.ORDERS,
               name: 'Orders',
               component: Orders,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.MANAGER,
                     UserRolesName.TECHNICIAN,
                     UserRolesName.RESELLER,
                     UserRolesName.ADMINISTRATOR,
                  ],
               },
            },
            {
               path: Pages.USERS,
               name: 'Consumers',
               component: Users,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.MANAGER,
                     UserRolesName.ADMINISTRATOR,
                  ],
               },
            },
            {
               path: Pages.PAYMENT,
               name: '"Payment Details"',
               component: Payment,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.TRADER,
                     UserRolesName.SELLER,
                     UserRolesName.TECHNICIAN,
                     UserRolesName.RESELLER,
                  ],
               },
            },
            {
               path: Pages.STORE_IMAGE,
               name: 'Store Advertisement',
               component: StoreImage,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.TECHNICIAN,
                     UserRolesName.ADMINISTRATOR,
                  ],
               },
            },
            {
               path: Pages.ALL_PRODUCT,
               name: 'View Product',
               component: AllProductView,
               meta: {
                  visibility: [
                     UserRolesName.SUPER_ADMIN,
                     UserRolesName.TRADER,
                     UserRolesName.SELLER,
                     UserRolesName.TECHNICIAN,
                     UserRolesName.RESELLER,
                  ],
               },
            },
            {
               name: 'Pending Approval',
               path: Pages.PENDING_APPROVAL,
               component: ApprrovalList,
            },
            {
               path: Pages.MY_PROFILE,
               name: 'My Profile',
               component: Profile,
            },
            {
               path: Pages.DIRECTORY,
               name: 'Directory',
               component: Directory,
            },
            {
               path: Pages.BUYER_SUB_PROFILE,
               name: 'Reseller Sub-profile',
               component: BuyerSubProfile,
            },
            {
               path: Pages.CURRENCIES,
               name: 'Currencies',
               component: Currencies,
            },
            {
               path: Pages.COMMISSION,
               name: 'Commission',
               component: Commission,
            },
            {
               name: 'Forums',
               path: Pages.FORUMS,
               component: Forums,
            },
            {
               name: 'Message',
               path: Pages.MESSAGE,
               component: Message,
            },
            {
               name: 'Weather',
               path: Pages.WEATHER,
               component: Weather,
            },
         ],
      },
      {
         path: '/',
         redirect: Pages.HOME,
         name: 'Home',
         component: HomeContainer,
         children: [
            {
               path: '/home/:text',
               name: 'Homes',
               component: Home,
            },
            {
               path: Pages.HOME,
               name: 'Home',
               component: Home,
            },
            {
               path: Pages.DIRECTORY_HOME,
               name: 'Directory home',
               component: DirectoryHome,
            },

            {
               path: Pages.CART_DETAIL,
               name: 'cartDetail',
               component: HomeProductDetails,
            },
            {
               path: Pages.PRODUCT_DESCRIPTION,
               name: 'productDescription',
               component: HomeProductDescription,
            },
            {
               path: '/product-description/:id',
               name: 'productDescriptionId',
               component: HomeProductDescription,
            },

            {
               path: Pages.PRODUCT_PAYMENT,
               name: 'payment',
               component: payment,
            },
            {
               path: Pages.ADDRESS,
               name: 'Address',
               component: ProductAddress,
            },
            {
               path: Pages.PAYMENT_TYPE,
               name: 'PaymentType',
               component: PaymentType,
            },
            {
               path: Pages.PAYMENT_ACCEPT,
               name: 'Payment Accepted',
               component: PaymentAccept,
            },
            {
               path: Pages.PAYMENT_DENY,
               name: 'Payment Denied',
               component: PaymentDeny,
            },
            {
               path: Pages.PAYMENT_CANCEL,
               name: 'Payment Cancel',
               component: PaymentCancel,
            },
            {
               path: Pages.CHAT,
               name: 'chat',
               component: Chat,
            },
            {
               path: '/chat/:userId',
               name: 'chat',
               component: Chat,
            },
            {
               path: Pages.FORUMS_MESSAGE,
               name: 'Forums Message',
               component: ForumsMessage,
            },
            {
               path: `${Pages.FORUMS_MESSAGE}/:forumId`,
               name: 'Forums Message',
               component: ForumsMessageDetail,
            },
         ],
      },
      {
         path: Pages.FORGOT_PASSWORD,
         name: 'Forgot Password',
         component: ForgotPassword,
      },
      {
         path: Pages.RESET_PASSWORD,
         name: 'Reset Password',
         component: ResetPassword,
      },
      {
         path: Pages.SIGN_UP,
         name: 'Sign-Up',
         component: SignUp,
      },
      {
         path: Pages.HOME,
         name: 'Home',
         component: Home,
      },
      {
         path: Pages.ADMIN,
         name: 'admin',
         component: Login,
      },
      {
         path: Pages.VENDOR,
         name: 'vendor',
         component: Login,
      },
      {
         path: Pages.CONSUMER,
         name: 'consumer',
         component: Login,
      },
      {
         path: Pages.PAGES,
         redirect: Pages.PAGES_LOGIN,
         name: 'Pages',
         component: {
            render(c) {
               return c('router-view');
            },
         },
         children: [
            {
               path: Pages.LOGIN,
               name: 'Login',
               component: Login,
            },
         ],
      },

      {
         path: Pages.PAGES,
         redirect: Pages.PAGES_CHANGE_PASSWORD,
         name: 'Pages',
         component: {
            render(c) {
               return c('router-view');
            },
         },
         children: [
            {
               path: Pages.CHANGE_PASSWORD,
               name: 'change password',
               component: changepassword,
            },
         ],
      },

      {
         path: Pages.PAGES_CHANGEPASSWORD_SUCCESSFULLY,
         redirect: Pages.PAGES_CHANGEPASSWORD_SUCCESSFULLY,
         name: 'Pages',
         component: {
            render(c) {
               return c('router-view');
            },
         },
         children: [
            {
               path: Pages.CHANGEPASSWORD_SUCCESSFULLY,
               name: 'change password',
               component: changepasswordsuccessfully,
            },
         ],
      },
   ],
});

router.beforeEach((to, from, next) => {
   const auth_token = window.localStorage.getItem('auth_token');
   const user = getLoginUser();

   // Define paths that don't require authentication
   const publicPaths = [
      Pages.HOME,
      Pages.FORUMS_MESSAGE,
      Pages.CHAT,
      Pages.ADDRESS,
      Pages.PRODUCT_PAYMENT,
      Pages.ADMIN,
      Pages.VENDOR,
      Pages.CONSUMER,
      Pages.LOGIN,
      Pages.FORGOT_PASSWORD,
      Pages.SIGN_UP,
      Pages.ORDERS,
      '/',
   ];

   // Define paths that can be matched with includes
   const includedPaths = [
      Pages.HOME,
      `${Pages.HOME_CHAT}/`,
      Pages.CHAT,
      `${Pages.HOME_PRODUCT_DESCRIPTION}/`,
      Pages.PRODUCT_DESCRIPTION,
      Pages.RESET_PASSWORD,
   ];

   // Check if the current path matches any of the public paths or included paths
   const isPublicPath = publicPaths.includes(to.path);
   const isIncludedPath = includedPaths.some((path) => to.path.includes(path));

   // Redirect to change password successfully page if needed
   if (to.path === Pages.CHANGEPASSWORD_SUCCESSFULLY) {
      return next(Pages.CHANGEPASSWORD_SUCCESSFULLY);
   }

   // If the path requires authentication and the user is not authenticated
   if (!isPublicPath && !isIncludedPath) {
      if (user && user.Role && auth_token) {
         // Check visibility and user permissions
         if (to.meta.visibility) {
            if (
               user.Users[0].modules.includes(to.name) ||
               user.Users[0].modules.includes('All')
            ) {
               return next();
            } else {
               return next(Pages.DASHBOARD);
            }
         } else {
            return next();
         }
      } else if (to.path === Pages.CHANGE_PASSWORD) {
         // Handle change password separately if needed
      } else {
         // Redirect unauthenticated users to home page
         return next(Pages.HOME);
      }
   }

   // Continue if the path is public or included
   return next();
});

/*
router.beforeEach((to, from, next) => {
  if(to.path === Pages.CHANGEPASSWORD_SUCCESSFULLY){
    this.$router.push(Pages.CHANGEPASSWORD_SUCCESSFULLY);
  }

  if(to.path !==  Pages.HOME  && to.path !==  Pages.FORUMS_MESSAGE && to.path !==  Pages.CHAT &&  !to.path.includes( Pages.HOME ) &&  !to.path.includes(Pages.HOME_CHAT+"/") &&  !to.path.includes( Pages.CHAT)&&  !to.path.includes(`${Pages.HOME_PRODUCT_DESCRIPTION}/`) &&  !to.path.includes(Pages.PRODUCT_DESCRIPTION)  && to.path !==  Pages.ADDRESS && to.path !==  Pages.PRODUCT_PAYMENT && to.path !== Pages.ADMIN && to.path !==  Pages.VENDOR && to.path !==  Pages.CONSUMER && to.path !==  Pages.LOGIN && to.path !== '/' && to.path !==  Pages.FORGOT_PASSWORD  && to.path !==  Pages.SIGN_UP && !to.path.includes( Pages.RESET_PASSWORD)) {
    const auth_token = window.localStorage.getItem('auth_token');
    var user =  getLoginUser();  
    if(user && user.Role && auth_token) {
      if(to.meta.visibility){
        if(user.Users[0].modules.includes(to.name) || user.Users[0].modules.includes('All')){
          next();
        }else {
          next(Pages.DASHBOARD);
        }
      } else {
        next();
      }
    } else if(to.path === Pages.CHANGE_PASSWORD){

    }
    else {
      next( Pages.HOME );
    }
  }else{
    next();
  }
})
*/

export default router;
