import { apiUrl } from '@/views/toutPaie/ToutPaieService';
import {
   ApiRoutesWithoutPrefix,
   AppCookies,
   DATE_FORMAT,
   defaultLocale,
   LocalStorageKeys,
   UserRolesName,
} from '@/config/constant';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import the locale you want to use
import localizedFormat from 'dayjs/plugin/localizedFormat'; // Import the localizedFormat plugin
import relativeTime from 'dayjs/plugin/relativeTime';

// Extend dayjs with the localizedFormat plugin
dayjs.extend(localizedFormat);

dayjs.extend(relativeTime);

export const capitalizeFirstLetter = (word) => {
   if (typeof word !== 'string') {
      return '';
   }
   return word.charAt(0).toUpperCase() + word.slice(1);
};
export const formatDate = (date, locale = defaultLocale, withHour = true) => {
   // This function should be used for converting ISO formatted dates to
   // the 'DD/MM/YYYY' or 'DD/MM/YYYY hh:mm:ss' format used everywhere on the project.
   if (!date || !dayjs(date).isValid()) {
      return '-';
   }

   const formatedDate = dayjs(date)
      .locale(locale)
      .format(withHour ? DATE_FORMAT.DATETIME : DATE_FORMAT.DATE);
   return locale === defaultLocale ? capitalizeFirstLetter(formatedDate) : formatedDate;
};

export const parseDate = (date, locale = defaultLocale) => {
   let parsedDate = dayjs(date);
   parsedDate = parsedDate.locale(locale);

   return parsedDate.isValid() ? parsedDate : dayjs(date);
};
export const getApiImageUrl = (target) => {
   if (target === null)
      if (/^(http|https):\/\//.test(target)) {
         return target;
      }
   return apiUrl + ApiRoutesWithoutPrefix.IMAGES + '/' + target;
};
export const retrieveAuthTokenName = (type) => {
   if (type === 'access') {
      return `${AppCookies.ACCESS_TOKEN}`;
   } else {
      return `${AppCookies.REFRESH_TOKEN}`;
   }
};

export const isAdminOrSuperAdmin = (role) => {
   return (
      role === UserRolesName.SUPER_ADMIN ||
      role === UserRolesName.ADMINISTRATOR ||
      role === UserRolesName.CRDE ||
      role === UserRolesName.CHAMBRE_DE_COMMERCE ||
      role === UserRolesName.CHAMBRE_DE_LAGRICULTURE
   );
};

export const getLoginUser = () => {
   const loginUser = localStorage.getItem(LocalStorageKeys.LOGIN_USER);
   if (loginUser) {
      return JSON.parse(loginUser);
   }
   return null;
};
