import { ApiRoutesWithoutPrefix, UserRoles } from '@/config/constant.js';
import { axiosInstance, noAuthAxiosInstance } from '@/utils/axios';
import { getLoginUser } from '@/utils';
export const apiUrl = process.env.VUE_APP_API_END_POINT;
const serverApiEndPoint = apiUrl;
export const publishableKeys = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

export const login = (user) => {
   return axiosInstance
      .post(serverApiEndPoint + ApiRoutesWithoutPrefix.USER_LOGIN, user)
      .then((response) => {
         return response?.data?.[0];
      })
      .catch((err) => {
         throw err;
      });
};

export const signUp = (user) => {
   return noAuthAxiosInstance
      .post(
         `${serverApiEndPoint}${
            String(user?.RoleID) === String(UserRoles.USER)
               ? ApiRoutesWithoutPrefix.USER_REGISTER
               : ApiRoutesWithoutPrefix.ADMIN_USER_REGISTER
         }`,
         user,
      )
      .then((response) => {
         response?.data?.[0];
      })
      .catch((err) => {
         throw err;
      });
};

export const forgotPassword = (user) => {
   return axiosInstance
      .post(serverApiEndPoint + ApiRoutesWithoutPrefix.USER_PASSWORD_FORGOT, user, {})
      .then((response) => response?.data?.[0])
      .catch((err) => {
         throw err;
      });
};

export const resetPassword = (user) => {
   return axiosInstance
      .post(serverApiEndPoint + ApiRoutesWithoutPrefix.USER_PASSWORD_RESET, user, {})
      .then((response) => response?.data?.[0])
      .catch((err) => {
         throw err;
      });
};

export const logout = () => {
   return axiosInstance
      .get(serverApiEndPoint + ApiRoutesWithoutPrefix.ADMIN_LOGOUT)
      .then((response) => response.data)
      .catch((err) => {
         throw err;
      });
};

export const getData = (URL) => {
   const loginUser = getLoginUser();
   const token = loginUser?.headers.RememberToken;
   return axiosInstance
      .get(serverApiEndPoint + URL, {
         headers: {
            token: token,
         },
      })
      .then((response) => response?.data?.[0])
      .catch((err) => {
         throw err;
      });
};

export const saveData = (formData, url) => {
   const loginUser = getLoginUser();
   const token = loginUser ? loginUser?.headers?.RememberToken : '';
   return axiosInstance
      .post(serverApiEndPoint + url, formData, {
         headers: {
            token: token,
         },
      })
      .then((response) => response)
      .catch((err) => {
         throw err;
      });
};

export const filterData = (formData, url) => {
   return axiosInstance
      .post(serverApiEndPoint + url, formData, {
         headers: {},
      })
      .then((response) => response)
      .catch((err) => {
         throw err;
      });
};

export const deleteData = (data, URL) => {
   return axiosInstance
      .delete(serverApiEndPoint + URL, { data: data })
      .then((response) => response?.data?.[0])
      .catch((err) => {
         throw err;
      });
};

export const getRolesData = (URL) => {
   return axiosInstance
      .get(serverApiEndPoint + URL)
      .then((response) => response)
      .catch((err) => {
         throw err;
      });
};

export const getRolesDataByName = (obj, URL) => {
   return axiosInstance
      .post(serverApiEndPoint + URL, obj)
      .then((response) => response)
      .catch((err) => {
         throw err;
      });
};

export const searchByActor = (obj, url) => {
   return axiosInstance
      .post(serverApiEndPoint + url, obj, {})
      .then((response) => response?.data?.[0])
      .catch((err) => {
         throw err;
      });
};

export const getDataWithOffers = (URL) => {
   return axiosInstance
      .get(serverApiEndPoint + URL, {})
      .then((response) => response?.data?.[0])
      .catch((err) => {
         throw err;
      });
};

export const getProductByLatLong = (obj) => {
   return axiosInstance
      .post(serverApiEndPoint + ApiRoutesWithoutPrefix.PRODUCT_LIST_BYLATLONG, obj, {})
      .then((response) => response?.data?.[0])
      .catch((err) => {
         throw err;
      });
};
